import React from "react";
import "./ScrollToTop.css";
import ArrowUp from "./arrow_up_1.png";

const ScrollToTop = ({ visible }) => {
  return (
    <div
      id="return-to-top"
      role="button"
      style={{ display: visible ? "block" : "none" }}
      onClick={() => {
        if (typeof window !== `undefined`) {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      }}
      onKeyDown={() => {
        if (typeof window !== `undefined`) {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      }}
      aria-hidden="true"
    >
      <img src={ArrowUp} alt="move to top" />
    </div>
  );
};

export default ScrollToTop;
