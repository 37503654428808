import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ScrollToTop from "./scroll-to-top/ScrollToTop";
import useIntersect from "./scroll-to-top/useIntersection";

import "./all.sass";

const TemplateWrapper = ({ children }) => {
  const [ref, entry] = useIntersect({
    threshold: 1,
  });

  return (
    <>
      <div
        style={{
          display: "flex",
          minHeight: "100vh",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <nav
          className="navbar is-primary"
          role="navigation"
          aria-label="main-navigation"
          ref={ref}
        >
          <Navbar />
        </nav>
        <div>{children}</div>
        <ScrollToTop visible={!entry.isIntersecting} />
        <Footer />
      </div>
    </>
  );
};

export default TemplateWrapper;
