import React from "react";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer pb-6" style={{ background: "#00adb5" }}>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter has-text-right">
            <div style={{ maxWidth: "100vw" }} className="columns"></div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
