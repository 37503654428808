import React from "react";
import { Link } from "gatsby";
import logo from "../../static/img/logo.png";
import instagram from "../img/social/instagram.svg";
import pinterest from "../img/social/pinterest.svg";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "",
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active",
            })
          : this.setState({
              navBarActiveClass: "",
            });
      }
    );
  };

  render() {
    return (
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item" title="Logo">
            <img src={logo} alt="multiintolerant-na-und?" />
          </Link>
          <div className="navbar-end">
            <div
              className="column is-1 social"
              style={{ position: "absolute", right: "30px" }}
            >
              <a
                title="instagram"
                href="https://www.instagram.com/multiintolerant_na.und/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src={instagram}
                  alt="Instagram"
                  style={{ width: "1em", height: "1em" }}
                />
              </a>
            </div>
            <div
              className="column is-1 social"
              style={{ position: "absolute", right: "90px" }}
            >
              <a
                title="pinterest"
                href="https://www.pinterest.at/michaelakreming/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src={pinterest}
                  alt="pinterest"
                  style={{ width: "1em", height: "1em" }}
                />
              </a>
            </div>
          </div>
          {/* Hamburger menu */}
          <div
            className={`navbar-burger burger ${this.state.navBarActiveClass}`}
            data-target="navMenu"
            onClick={() => this.toggleHamburger()}
            onKeyDown={() => this.toggleHamburger()}
            aria-hidden="true"
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div
          id="navMenu"
          className={`navbar-menu ${this.state.navBarActiveClass}`}
        >
          <div className="navbar-start has-text-centered">
            <Link className="navbar-item" to="/blog">
              Blog
            </Link>
            <Link className="navbar-item" to="/rezepte">
              Rezepte
            </Link>
            <Link className="navbar-item" to="/links">
              Links
            </Link>
            <Link className="navbar-item" to="/about">
              Über mich
            </Link>
          </div>
        </div>
      </div>
    );
  }
};

export default Navbar;
